import React, { useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { useQuery, useMutation } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import Grid from "@material-ui/core/Grid";
import { textLabels } from "./dataTablesLocalization";
import { Dialog, DialogTitle, Button } from "@material-ui/core";
import { Form, Formik, Field } from "formik";
import axios from "axios";
import SavedSnack from "./SavedSnack";
import Upload from "./Upload";

const query = gql`
  query getProjects($evaluator: String, $turma: Int) {
    person(
      where: {
        project_evaluators: {
          evaluator: { _eq: $evaluator }
          turma: { _eq: $turma }
          deleted_at: { _is_null: true }
        }
        deleted_at: { _is_null: true }
      }
      order_by: { first_name: asc, last_name: asc }
    ) {
      id
      first_name
      last_name
      email
      activity_completions(
        where: {
          _or: [
            {
              file: { _is_null: false }
              activityByActivity: {
                activity_type: {
                  _or: [
                    { group: { _eq: "Projeto" } }
                    { group: { _eq: "Upload" } }
                    { group: { _eq: "TCC" } }
                    { group: { _eq: "ETC" } }
                    { group: { _eq: "Avaliação CCA" } }
                  ]
                }
              }
            }
            {
              activityByActivity: {
                activity_type: {
                  _or: [
                    { group: { _eq: "Produzir Conteúdo - workshop" } }
                    { group: { _eq: "ETC" } }
                  ]
                }
              }
            }
          ]
          activityByActivity: {
            lessonByLesson: {
              turma: { _eq: $turma }
              deleted_at: { _is_null: true }
            }
            deleted_at: { _is_null: true }
          }
          deleted_at: { _is_null: true }
        }
      ) {
        id
        activity
        comment
        date_done
        file
        monitor_comment
        monitor_file
        file_video_etc
        file_folder_etc
        file_summary_etc
        completed
        activityByActivity {
          type
          ui_order
          title
          lessonByLesson {
            disciplina
            turma
          }
        }
      }
      courseworks(
        where: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
      ) {
        grade
        title
        disciplina
        grade_weight
      }
    }
  }
`;

const mutation = gql`
  mutation feedback($id: Int!, $obs: String, $monitor_file: String) {
    update_activity_completion_by_pk(
      pk_columns: { id: $id }
      _set: { monitor_comment: $obs, monitor_file: $monitor_file }
    ) {
      id
      monitor_comment
      monitor_file
    }
  }
`;

const gradeMutation = gql`
  mutation insertGrade($object: coursework_insert_input!) {
    insert_coursework_one(
      object: $object
      on_conflict: {
        constraint: coursework_title_turma_person_disciplina_key
        update_columns: [grade, grade_weight]
      }
    ) {
      id
      grade
      grade_weight
    }
  }
`;

const Evaluation = observer((props) => {
  const [dialogState, setDialogState] = useState({
    open: false,
    completion: {},
    person: null,
  });
  const [snack, setSnack] = useState({ open: false, msg: "" });
  const store = useContext(StoreContext);
  const [update] = useMutation(mutation);
  const [insertGrade] = useMutation(gradeMutation);
  const turma = store.ui.turma.id;
  var { data, error, loading, refetch } = useQuery(query, {
    variables: { turma, evaluator: store.email },
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (Coursework.jsx): {error.message}</p>;
  data = data.person;
  var columns = ["first_name", "last_name", "email"];
  data.forEach((person) => {
    person.activity_completions.forEach((ac) => {
      var act = ac.activityByActivity;
      var type = act.title;
      person[type] = act.title;
      let exists = false;
      columns.forEach((column) => {
        if (column === type || (column.name && column.name === type))
          exists = true;
      });
      if (!exists)
        columns.push({
          name: type,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              const person = data[tableMeta.rowIndex];
              const activityTitle = act.title;
              const coursework = person.courseworks.find((cw) =>
                matchTitles(activityTitle, cw.title)
              );

              const hasGrade =
                coursework &&
                coursework.grade !== null &&
                coursework.grade !== undefined &&
                coursework.grade !== "";

              var style = {
                cursor: "pointer",
                textDecoration: "underline",
                color: hasGrade ? "inherit" : "red",
              };

              return (
                <div
                  style={style}
                  onMouseEnter={(e) => {
                    e.target.style.textDecoration = "none";
                    e.target.style.color = hasGrade ? "#ca292c" : "darkred";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.textDecoration = "underline";
                    e.target.style.color = hasGrade ? "inherit" : "red";
                  }}
                  onClick={() => {
                    const completion = { ...ac };
                    if (coursework) {
                      completion.grade = coursework.grade;
                    } else {
                      completion.grade = "sem nota";
                    }
                    setDialogState({
                      open: true,
                      completion,
                      person: person,
                    });
                  }}
                >
                  {value}
                </div>
              );
            },
          },
        });
    });
  });
  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9\s]/g, "")
      .replace(/\s+/g, " ")
      .trim();
  };

  const matchTitles = (activityTitle, courseworkTitle) => {
    const normalizedActivity = normalizeString(activityTitle);
    const normalizedCoursework = normalizeString(courseworkTitle);

    if (normalizedActivity === normalizedCoursework) return true;

    const matchFirstDelivery =
      (normalizedActivity.includes("1") ||
        normalizedActivity.includes("primeira")) &&
      normalizedCoursework.includes("primeira entrega");

    const matchSecondDelivery =
      (normalizedActivity.includes("2") ||
        normalizedActivity.includes("segunda")) &&
      normalizedCoursework.includes("segunda entrega");

    const matchFinalDelivery =
      normalizedActivity.includes("final") &&
      normalizedCoursework.includes("entrega final");

    return matchFirstDelivery || matchSecondDelivery || matchFinalDelivery;
  };

  const handleDialogOpen = (completion, person) => {
    const coursework = person.courseworks.find((cw) =>
      matchTitles(completion.activityByActivity.title, cw.title)
    );
    if (
      coursework &&
      coursework.grade !== null &&
      coursework.grade !== undefined &&
      coursework.grade !== ""
    ) {
      completion.grade = coursework.grade;
    } else {
      completion.grade = "";
    }
    setDialogState({
      open: true,
      completion,
      person,
    });
  };
  const showMsg = (msg) => {
    setSnack({ open: true, msg });
  };
  let disciplina =
    dialogState.completion.activityByActivity?.lessonByLesson?.disciplina;
  return (
    <>
      <p style={{ textAlign: "start", color: "red", fontSize: "17px" }}>
        {" "}
        *Atividades com título em vermelho não possuem nota.
      </p>
      <Grid container>
        <MUIDataTable
          title="Alunos"
          data={data}
          columns={columns}
          options={{
            responsive: "scroll",
            rowsPerPageOptions: [10, 50, 100],
            rowsPerPage: 100,
            onChangeRowsPerPage: (n) => (store.ui.alunos.rowsPerPage = n),
            onColumnViewChange: (col, action) => {
              const dict = { add: true, remove: false };
              store.ui.alunos.columns[col] = dict[action];
            },
            selectableRows: false,
            onCellClick: (_, cmeta) => {
              const cname = columns[cmeta.colIndex].name;
              const person = data[cmeta.dataIndex];
              var completion = {};
              if (cmeta.colIndex > 2) {
                person.activity_completions.forEach((ac) => {
                  if (ac.activityByActivity.title === cname) completion = ac;
                });
                handleDialogOpen(completion, person);
              }
            },
            textLabels,
          }}
        />
        <Dialog
          open={dialogState.open}
          onClose={() => setDialogState({ ...dialogState, open: false })}
        >
          {dialogState.completion.activityByActivity?.type === "ETC - vídeo" ? (
            <>
              {dialogState.completion.file_video_etc && (
                <p style={{ textAlign: "center" }}>
                  <a
                    href={`https://drive.google.com/file/d/${dialogState.completion.file_video_etc}/view`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Vídeo do ETC
                  </a>
                </p>
              )}
              {dialogState.completion.file_folder_etc && (
                <p style={{ textAlign: "center" }}>
                  <a
                    href={
                      "https://drive.google.com/uc?export=view&id=" +
                      dialogState.completion.file_folder_etc
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Folder do ETC
                  </a>
                </p>
              )}
              {dialogState.completion.file_summary_etc && (
                <p style={{ textAlign: "center" }}>
                  <a
                    href={
                      "https://drive.google.com/uc?export=view&id=" +
                      dialogState.completion.file_summary_etc
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Resumo do Arquivo ETC
                  </a>
                </p>
              )}
            </>
          ) : (
            dialogState.completion.file &&
            dialogState.completion.file.split(" ").map((file, i) => {
              return (
                <p style={{ textAlign: "center" }} key={i}>
                  <a
                    href={"https://drive.google.com/uc?export=view&id=" + file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Arquivo {i + 1}
                  </a>
                </p>
              );
            })
          )}
          <DialogTitle>
            {dialogState.completion.activityByActivity
              ? dialogState.completion.activityByActivity.type
              : ""}
          </DialogTitle>
          <p className="p30">{dialogState.completion.comment}</p>
          <Formik
            enableReinitialize={true}
            initialValues={{
              obs: dialogState.completion.monitor_comment,
              monitor_file: dialogState.completion.monitor_file,
              grade: dialogState.completion.grade || "",
            }}
            onSubmit={async (values, actions) => {
              try {
                await update({
                  variables: {
                    id: dialogState.completion.id,
                    obs: values.obs,
                    monitor_file: values.monitor_file,
                  },
                });

                if (values.grade && values.grade !== "sem nota") {
                  const gradeObject = {
                    person: dialogState.person.id,
                    turma:
                      dialogState.completion.activityByActivity.lessonByLesson
                        .turma,
                    disciplina:
                      dialogState.completion.activityByActivity.lessonByLesson
                        .disciplina,
                    grade: values.grade,
                    title: dialogState.completion.activityByActivity.title,
                    grade_weight: disciplina !== 84 ? 10 : undefined,
                  };

                  await insertGrade({
                    variables: { object: gradeObject },
                  });
                }

                showMsg("Alterações salvas com sucesso");
                refetch();
                const config = {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("auth0:id_token"),
                  },
                };
                var body = {
                  to: dialogState.person.email,
                  text: `Feedback adicionado sobre o trabalho ${dialogState.completion.activityByActivity.type} - ${dialogState.completion.activityByActivity.title}:`,
                };
                if (values.monitor_file)
                  body.text +=
                    "\nhttps://drive.google.com/uc?id=" + values.monitor_file;
                if (values.grade) body.text += "\nNota: " + values.grade;
                if (values.obs) body.text += "\n" + values.obs;
                await axios.post(
                  "/.netlify/functions/mailnotification",
                  body,
                  config
                );
              } catch (e) {
                showMsg("Erro");
              }
              actions.setSubmitting(false);
            }}
            render={({ values, setFieldValue }) => (
              <Form style={{ padding: "30px", minWidth: "600px" }}>
                <h3>Arquivo de retorno:</h3>
                <Upload
                  setFieldValue={setFieldValue}
                  prefix={store.email}
                  oldFile={dialogState.completion.monitor_file}
                  folder="coursework"
                  turma={store.ui.turma.course}
                  field="monitor_file"
                />
                <h3>Observações:</h3>
                <p>
                  <textarea
                    name="obs"
                    fullWidth={true}
                    defaultValue={dialogState.completion.monitor_comment}
                    onChange={(e) => {
                      setFieldValue("obs", e.target.value);
                    }}
                    cols={60}
                    rows={5}
                  />
                </p>
                <span>
                  {" "}
                  <b>Insira a nota: </b>{" "}
                </span>
                <Field
                  type="number"
                  name="grade"
                  value={values.grade === "sem nota" ? "" : values.grade}
                  required
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "16px",
                    marginRight: "20px",
                    width: "100px",
                  }}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ margin: "30px" }}
                >
                  Salvar alterações
                </Button>
                <p id="message" style={{ textAlign: "center" }} />
              </Form>
            )}
          />
        </Dialog>
        <SavedSnack
          open={snack.open}
          msg={snack.msg}
          setOpen={(open) => setSnack({ open })}
        />
      </Grid>
    </>
  );
});

export default Evaluation;
